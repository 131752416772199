import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, of } from 'rxjs';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { DeviceConfig, Session, Proof, Crono, Track, TrackStatus } from '../models/models';
import { take, tap, filter, map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
    providedIn: "root"
})
export class SessionService {
    private idSession: string = null
    private idAccount: string = null

    public readonly TRACK_SLOW: string = "slow"
    public readonly TRACK_FAST: string = "fast"

    private track: string = this.TRACK_SLOW

    private page: number

    constructor(private readonly db: AngularFireDatabase,
        private readonly auth: AuthService,
        private readonly afAuth: AngularFireAuth) {
        let size = 24 * 60 * 60 * 1000

        this.page = Math.round(Date.now() / size) * size
        this.initService()

    }

    initService() {

        this.track = this.TRACK_SLOW
        this.idSession = null
        this.idAccount = null
        this.auth.getAccountId().subscribe(
            accountId => {
                console.log("SESSION SERVICE: Nuovo Account ID Ricevuto")
                this.idAccount = accountId

            }
        )
    }

    setAccountId(id: string) {
        if (id) {
            this.idAccount = id
        }
    }

    public setTrack(value: string) {
        this.track = value
    }

    public getTrack() {
        return this.track
    }

    // PATH

    private get trackStatusPath(): string {
        return `accounts/${this.idAccount}/crono`
    }

    private get proofsPath(): string {
        return `accounts/${this.idAccount}/proofs`
    }

    private get proofsMetaPath(): string {
        return `accounts/${this.idAccount}/proofs_meta`
    }

    private get sessionsPath(): string {
        return `sessions/${this.idAccount}`
    }

    private get currentSessionPath(): string {
        return `${this.sessionsPath}/${this.idSession}`
    }

    private get trackPath(): string {
        return `${this.currentSessionPath}/track_${this.track}`
    }

    // METHODS

    // public getIsActive(): Observable<boolean> {
    //     console.log("track" + this.trackPath)
    //     return this.db.object<boolean>(`${this.trackPath}/is_active`).valueChanges()
    // }

    public getStartTimestamp(): Observable<number> {
        return this.db.object<number>(`${this.trackPath}/current_start`).valueChanges()
    }

    public getStopTimestamp(): Observable<number> {
        return this.db.object<number>(`${this.trackPath}/current_stop`).valueChanges()
    }



    // public setTimings(name: string, start: number, stop: number, idStudent: string) {
    //     if (name && idStudent && start && stop && stop >= start) {
    //         let key = this.db.list(`${this.trackPath}/timings/`).push('').key
    //         console.log("key : " + key)
    //         let time = {
    //             'id': key,
    //             'student': idStudent,
    //             'name': name,
    //             'duration': Math.round((stop - start) / 1000),
    //             'start': start,
    //             'stop': stop
    //         }
    //         this.db.object(`${this.trackPath}/timings/${key}`).update(time)
    //     }
    // }

    public setStartDevice(id: string) {
        if (id) {
            this.db.object(`${this.trackPath}`).update({ 'device_start': id })
        }
    }

    public getStartDevice(): Observable<string> {
        return this.db.object<string>(`${this.trackPath}/device_start`).valueChanges()
    }

    public setStopDevice(id: string) {
        if (id) {
            this.db.object(`${this.trackPath}`).update({ 'device_stop': id })
        }
    }


    public getDevicesConfig(): Observable<DeviceConfig[]> {
        return this.db.list<DeviceConfig>(`${this.currentSessionPath}/devices`).valueChanges()
    }

    public getDeviceConfig(id: string): Observable<DeviceConfig> {
        return this.db.object<DeviceConfig>(`${this.currentSessionPath}/devices/${id}`).valueChanges()
    }

    public setDevicesConfig(deviceConfig: DeviceConfig[]) {
        return this.db.object(`${this.currentSessionPath}/devices`).set(deviceConfig)
    }

    public setCurrentSession(id: string) {
        this.idSession = id
        return this.db.object(`accounts/${this.idAccount}`).update({ 'current_session': id })
    }


    public getProofs() {
        return this.db.list<Proof>(`${this.proofsPath}`).valueChanges()
    }

    public getProofsMeta() {
        return this.db.list<Proof>(`${this.proofsMetaPath}`, ref => {
            return ref.limitToLast(20)
        }).valueChanges()
    }

    public selectTrack(track: string) {
        return this.db.object(`${this.trackStatusPath}/current`).set(track)
    }

    public getCrono() {
        return this.db.object<Crono>(`${this.trackStatusPath}`).valueChanges()
    }

    public enableTrack() {
        let payload = {}
        if (this.track == this.TRACK_SLOW) {
            payload[`${this.TRACK_SLOW}/is_enable`] = true
            payload[`${this.TRACK_FAST}/is_enable`] = false
            payload[`${this.TRACK_FAST}/start`] = null
            payload[`${this.TRACK_FAST}/stop`] = null
        } else {
            payload[`${this.TRACK_FAST}/is_enable`] = true
            payload[`${this.TRACK_SLOW}/is_enable`] = false
            payload[`${this.TRACK_SLOW}/start`] = null
            payload[`${this.TRACK_SLOW}/stop`] = null
        }

        return this.db.object(`${this.trackStatusPath}`).update(payload)
    }

    public disableTrack() {
        return this.db.object(`${this.trackStatusPath}/${this.track}/is_enable`).set(false)
    }

    public resetTrack() {
        let payload = {
            start: null,
            start_frame: null,
            stop: null,
            stop_frame: null
        }
        return this.db.object(`${this.trackStatusPath}/${this.track}`).update(payload)
    }

    public getTrackStatus(): Observable<TrackStatus> {
        return this.db.object<TrackStatus>(`${this.trackStatusPath}/${this.track}`).valueChanges()
    }

    public setStartTrack(value: number) {
        return this.db.object(`${this.trackStatusPath}/${this.track}/start`).set(value).then(_ => {
            if (!value)
                return this.db.object(`${this.trackStatusPath}/${this.track}/start_frame`).set(value)
        })
    }

    public setStopTrack(value: number) {
        return this.db.object(`${this.trackStatusPath}/${this.track}/stop`).set(value).then(_ => {
            if (!value)
                return this.db.object(`${this.trackStatusPath}/${this.track}/stop_frame`).set(value)
        })
    }

    public pushProof(start: number, start_frame: string, stop: number, stop_frame: string,
        duration: number, duration_showed: number, name: string, idStudent: string) {

        let id = this.db.list(`${this.proofsPath}`).push('').key
        console.log("key : " + id)
        let time = {
            id,
            name,
            student: idStudent,
            start,
            start_frame: start_frame || null,
            stop,
            stop_frame: stop_frame || null,
            duration,
            duration_showed,
            page: this.page,
            track: this.track
        }

        this.pushProofMeta(id, time.name, time.duration, time.track)
        return this.db.object(`${this.proofsPath}/${id}`).update(time).then(_ => time)

    }

    public pushProofMeta(id: string, name: string, duration: number, track: string) {
        let proofMeta = {
            id,
            name,
            duration,
            track
        }
        return this.db.object(`${this.proofsMetaPath}/${id}`).set(proofMeta)
    }

    public updateProofName(id: string, name: string) {
        return this.db.object(`${this.proofsPath}/${id}/name`).set(name)
    }

    public removeProof(id: string) {
        this.db.object(`${this.proofsMetaPath}/${id}`).remove()
        return this.db.object(`${this.proofsPath}/${id}`).remove()
    }

    // public getProof(track: string) {
    //     return this.db.list<Proof>(`${this.proofsPath}`).valueChanges()
    // }

    public getProof(id: string) {
        return this.db.object<Proof>(`${this.proofsPath}/${id}`).valueChanges()
    }

    public updateSingleMode(enable: boolean) {
        return this.db.object(`${this.trackStatusPath}/single_mode`).set(enable)
    }
}