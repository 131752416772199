import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { DeviceService } from 'src/app/services/device.service';
import { Subscription } from 'rxjs';
import { Device, WifiSignal, DeviceStatus } from 'src/app/models/models';

@Component({
  selector: 'app-device-live',
  templateUrl: './device-live.component.html',
  styleUrls: ['./device-live.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DeviceLiveComponent implements OnInit {

  @Input() device: Device
  @Input() onlyPreview: boolean = false


  frame: string
  subscription: Subscription
  message: string
  loading: HTMLIonLoadingElement
  loader: boolean = true
  statusGood: number = DeviceStatus.GOOD_TARGET

  constructor(
    private readonly deviceService: DeviceService,
    private readonly modalController: ModalController,
    private readonly loadingController: LoadingController,
    private readonly cd: ChangeDetectorRef) {

  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.frame = null
    this.loader = true
    this.cd.markForCheck()
    this.deviceService.setLive(this.device.id, true)
    this.subscription = new Subscription()
    this.subscription.add(this.subHandleLive())
    this.subscription.add(this.subGetLiveFrame())
    this.subscription.add(this.subGetWifiSignal())
    this.subscription.add(this.subGetDeviceStatus())
  }

  ionViewWillLeave() {
    if (this.subscription !== null) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  ionViewDidLeave() {
    this.frame = null
    this.deviceService.clearLive(this.device.id)
  }

  get style() {

    return {
      'background': this.device ? this.device.color : "#fff"
    }
  }

  subHandleLive(): Subscription {
    return this.deviceService.getIsLive(this.device.id).subscribe((value) => {
      if (!value) {
        this.deviceService.setLive(this.device.id, true)
      }
    })
  }

  subGetLiveFrame() {
    return this.deviceService.getLive(this.device.id).subscribe(frame => {
      if (frame) {
        this.frame = frame
      } else {
        this.frame = null
      }
      this.loader = false
      this.cd.markForCheck()
    })
  }

  subGetWifiSignal(){
    return this.deviceService.getWifiSignal(this.device.id).subscribe((wifi)=>{
      if(wifi){
        this.device.wifi = wifi
      }else{
        this.device.wifi = WifiSignal.NOT_CONNECTED
      }
      this.cd.markForCheck()
    })
  }

  subGetDeviceStatus(){
    return this.deviceService.getDeviceStatus(this.device.id).subscribe((status)=>{
      if(status){
        this.device.status = status
      }else{
        this.device.status = DeviceStatus.STANDBY
      }
      this.cd.markForCheck()
    })
  }

  close() {
    this.modalController.dismiss(null, 'cancel')
  }

  async getCoord(event) {
    this.loading = await this.loadingController.create({
      message: "Attendere...",
      spinner: 'crescent',
      duration: 3500
    })
    await this.loading.present()
    console.log("x coord: " + event.offsetX)
    // console.log("x screen " + event.screenX)
    console.log("y coord: " + event.offsetY)
    // console.log("y screen " + event.screenY)
    this.deviceService.setTouchX(this.device.id, event.offsetX)
    this.deviceService.setTouchY(this.device.id, event.offsetY)
  }


  refresh() {
    if (this.subscription) {
      this.loader = true
      this.cd.markForCheck()
      this.deviceService.setLive(this.device.id, true)
      this.subscription.unsubscribe()
      this.subscription = null
      this.frame = null
      this.message = null
      this.subscription = new Subscription()
      this.subscription.add(this.subHandleLive())
      this.subscription.add(this.subGetLiveFrame())
    }
  }


  get infoDevice(): string{
    return this.device.name
  }

  get infoTrack(): string{
    return this.device.position === 'start' ? "Partenza" : "Arrivo"
  }

  get wifiSignal(): string {

    switch (this.device.wifi) {
      case WifiSignal.NOT_CONNECTED:
        return "Non Connesso"

      case WifiSignal.ABSENT:
        return "Assente"

      case WifiSignal.LOW:
        return "Bassa"

      case WifiSignal.MEDIUM:
        return "Media"

      case WifiSignal.HIGH:
        return "Alta"

      default:
        return "Non Connesso"
    }
  }


  get deviceStatus(): string {

    switch (this.device.status) {

      case DeviceStatus.STANDBY:
        return "Preparazione alla calibrazione in corso..."

      case DeviceStatus.EVALUATION:
        return "Calibrazione in corso..."

      case DeviceStatus.GOOD_TARGET:
        return "Il dispositivo è pronto per la rilevazione. Accertati che stia inquadrando il birillo"

      case DeviceStatus.BAD_TARGET:
        return "ATTENZIONE! Calibrazione fallita. Inquadrare il birillo toccandolo sullo schermo"

      default:
        return "Errore di comunicazione con il server o con il dispositivo"
    }
  }

}
