import { Component, OnInit, Input } from '@angular/core';
import { Device, WifiSignal, DeviceStatus } from 'src/app/models/models';
import { DeviceService } from 'src/app/services/device.service';
import { ModalController, AlertController } from '@ionic/angular';
import { DeviceLiveComponent } from '../device-live/device-live.component';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss'],
})
export class DeviceCardComponent implements OnInit {

  @Input() device: Device
  @Input() label: string
  @Input() lite: boolean = false
  @Input() color: string
  @Input() supporting: boolean = false

  constructor(
    private readonly modalController: ModalController,
    private readonly deviceService: DeviceService,
    private readonly alertController: AlertController
  ) { }

  ngOnInit() {}

  openDevicePreview(onlyPreview: boolean) {
    if(!onlyPreview){
      this.supporting ? this.presentAlert() : this.presentPreview(this.device.id, onlyPreview)
    } else{
      this.presentPreview(this.device.id, onlyPreview)
    }
    
  }

  deleteDevice() {
    this.deviceService.resetDeviceConfig(this.device.id)
  }

  get style() {
    return {
      'background': this.device ? this.device.color : "#fff"
    }
  }

  async presentPreview(deviceId: string, onlyPreview: boolean) {
    this.deviceService.setLive(deviceId, true)
    const modal = await this.modalController.create({
      component: DeviceLiveComponent,
      componentProps: {
        'device': this.device,
        'onlyPreview': onlyPreview
      },
      cssClass: "modal-fullscreen"
    })
    modal.onDidDismiss().then(() => {
      this.deviceService.setLive(deviceId, false)
      // this.modalController.dismiss(null, 'cancel')
    })

    return await modal.present()
  }

  get wifiSignal(): string {

    if(this.device.wifi){
      switch (this.device.wifi) {
  
        case WifiSignal.ABSENT:
          return "Assente"
  
        case WifiSignal.LOW:
          return "Bassa"
  
        case WifiSignal.MEDIUM:
          return "Media"
  
        case WifiSignal.HIGH:
          return "Alta"
  
        default:
          return "Non Connesso"
      }
    }else{
      return "Non Connesso"
    }
  }

   get deviceStatus(): string {

    switch (this.device.status) {
      case DeviceStatus.STANDBY:
        return "Non Calibrato"

      case DeviceStatus.EVALUATION:
        return "Non Calibrato"

      case DeviceStatus.GOOD_TARGET:
        return "Pronto"

      case DeviceStatus.BAD_TARGET:
        return "Calibrazione Errata"

      default:
        return "Non Calibrato"
    }
  }


  public async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Vai a Calibra dispositivo',
      message: "La pagina alla quale stai cercando di accedere potrebbe creare conflitti con l'utilizzo del cronometro. Procedere comunque?",
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'SI',
          handler: () => {
            this.presentPreview(this.device.id, false)
          }
        }
      ]
    });

    await alert.present();
  }
}
