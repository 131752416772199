import { Target } from '@angular/compiler';

export interface User {
    id?: number;
    uid?: string;
    firstname?: string;
    surname?: string;
    email?: string;
    phone?: string;
    image?: string;
    is_staff?: boolean;
    is_admin?: boolean;
    operator?: any;
    // role?: string
}
export interface PaginatedApi<T> {
    count: number;
    next: string;
    prev: string;
    results: T[];
}

export interface SubscriptionStatus {
    agreement_date?: string
    first_payment?: string
    next_payment?: string
    stripe_customer_id?: string
    stripe_subscription_id?: string
    type?: string
    is_monthly?: boolean
    last4?: string
    exp_month?: string
    exp_year?: string
}

export interface BillingInfo {
    name: string
    street: string
    zip_code: string
    city: string
    province: string
    vat_number: string
    pec: string
}

export interface Device {
    id: string;
    account: string;
    color: string;
    last_read: string;
    name: string;
    online: boolean;
    session?: string;
    track?: string;
    position?: string;
    ping: number;
    wifi: WifiSignal;
    status: DeviceStatus;
}

export interface DeviceConfig {
    id: string;
    position: string;
    track: string;
}

export interface Account {
    id: string,
    current_session?: string,
    devices?: Device[],
    email: string,
    last_renew: number
    billing: BillingInfo
}

export interface Proof {
    id: string,
    student?: string,
    name: string,
    duration: number,
    value?: string,
    start?: number,
    stop?: number,
    start_frame?: string,
    stop_frame?: string,
    page?: number,
    track: string
}

export interface Track {
    is_active?: boolean
    device_start?: string
    device_stop?: string
    timings?: Proof[]

}

export interface Crono {
    current: string
    fast: TrackStatus
    slow: TrackStatus
}

export interface TrackStatus {
    is_enable: boolean
    start: number
    start_frame: string
    stop: number
    stop_frame: string
}

export interface Session {
    id: string
    devices?: DeviceConfig[]
    track_fast?: Track
    track_slow?: Track
}


export enum WifiSignal{
    NOT_CONNECTED = 0,
    ABSENT = 1,
    LOW = 2,
    MEDIUM = 3,
    HIGH = 4
}

export enum DeviceStatus{
    STANDBY = 0,
    EVALUATION = 1,
    BAD_TARGET = 2,
    GOOD_TARGET = 3,
    RECOGNIZING = 4,
    RECOGNIZED = 5
}

export enum CronoStatus{
    READY = 0,
    START = 1,
    PAUSE = 2
}

export interface DeviceSetup {
    slow_start?: Device
    slow_stop?: Device
    fast_start?: Device
    fast_stop?: Device
    one_track?: boolean
    count?: number
}

export interface Subscriptions{
    id: string,
    email: string
    subscription?: SubscriptionStatus
}
