import { Router } from "@angular/router";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { Storage } from '@ionic/storage'
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap, take, concatMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private user: User = null
    public lastAccountId: string

    constructor(
        private readonly afAuth: AngularFireAuth,
        private readonly storage: Storage,
        private readonly router: Router
    ) {
        this.afAuth.authState.subscribe(user => {
            this.user = user;
        })
    }


    async  login(email: string, password: string): Promise<auth.UserCredential> {
        this.storage.set('pwd', password)
        try {
            return await this.afAuth.auth.signInWithEmailAndPassword(email, password)
        } catch (e) {
            alert("Errore di autenticazione: email o password errati");
        }
    }

    async logout() {
        await this.afAuth.auth.signOut();
        localStorage.removeItem('user');
        this.router.navigate(['login']);
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return user !== null;
    }

    isUserLogged() {
        if (!this.isLoggedIn) {

            this.router.navigate(['login']);
        }
    }

    getUserAccount(): Observable<User> {
        return this.afAuth.authState
    }

    getAccountId(): Observable<string> {
        return this.getUserAccount().pipe(
            map(user => user != null ? user.uid : null),
            tap(accountId => this.lastAccountId = accountId)
        )
    }

    isLogged() {
        return this.getAccountId().pipe(
            take(1),
            map(accountId => accountId != null),
            tap(status => {
                if (!status) this.router.navigate(['login']);
            })
        )
    }

    getUser(): User {
        // if (this.isLoggedIn) {
        return this.user
        // }
    }

    getToken() {
        if (!this.user)
            return of(null)
        return fromPromise(this.storage.ready()).pipe(
            concatMap(_ => fromPromise(this.storage.get('pwd'))),
            map(pwd => this.user.email + "&&" + pwd)
        )
    }

}
