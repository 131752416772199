import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Observable, of, Subject } from 'rxjs'
import { fromPromise } from 'rxjs/internal-compatibility'
import { catchError, concatMap, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators'

import { AuthToken, UserAuthConfig, UserState } from './user-auth-models'

export type UserPermissionFilter<UserType> = (user: UserType) => Observable<boolean>

@Injectable()
export class UserAuthProvider<UserType> {
  lastUser: UserType

  /**
   * Notifica l'ultimo e i successivi stati dell'utente, filtrati per filterPermission se impostato
   */
  get userState(): Observable<UserState<UserType>> {
    return this.userStateSubject.pipe(
      filter(x => x != null)
    )
  }

  get connectionStatus(): Observable<{ isConnected: boolean }> {
    return this.connectionSubject.asObservable()
  }
  private userStateSubject: BehaviorSubject<UserState<UserType>>

  private refreshedToken: Subject<AuthToken>
  private authStateSubject: BehaviorSubject<boolean>
  private connectionSubject: BehaviorSubject<{ isConnected: boolean }> = new BehaviorSubject({ isConnected: true })

  private isRefreshing: boolean

  private permissionFilter: UserPermissionFilter<UserType> = null

  private lastUserState: UserState<UserType>

  constructor(
    public http: HttpClient,
    public storage: Storage,
    public config: UserAuthConfig
  ) {
    if (this.config) {
      // tslint:disable-next-line:no-console
      console.log('UserAuth init with config', this.config)
    } else {
      console.error('!! UserAuth init without config')
    }
    this.refreshedToken = new Subject<AuthToken>()
    this.authStateSubject = new BehaviorSubject<boolean>(null)
    this.userStateSubject = new BehaviorSubject<UserState<UserType>>(null)
    storage.ready().then(() => {
      this.getAuthToken().subscribe(
        token => {
          // tslint:disable-next-line:no-console
          console.log('Token from Storage: ')
          // tslint:disable-next-line:no-console
          console.log(token)
          this.authStateSubject.next(token && token.access_token ? true : false)
        },
        e => {
          this.authStateSubject.next(false)
        }
      )
    })


    this.authStateSubject.pipe(
      filter(x => x != null),
      distinctUntilChanged(),
      concatMap(state => {
        return state ? this.getUserFromApi() : of(null)
      }
      ),
      map(user => {
        return {
          logged: user != null,
          user
        } as UserState<UserType>
      }),
      concatMap(userState => {
        if (userState.logged && this.permissionFilter != null) {
          return this.permissionFilter(userState.user).pipe(
            catchError(_ => of(false)),
            concatMap(hasPermission => {
              userState.has_permission = hasPermission

              return userState.has_permission ? of(userState)
                : this.removeAuthToken().pipe(
                  concatMap(_ => of(userState))
                )
            })
          )
        } else {
          userState.has_permission = true

          return of(userState)
        }
      })
    ).subscribe(userState => {
      this.pushNewUserState(userState)
    },
      e => {
        console.error(e)
        this.pushNewUserState(null)
      },
      () => {
        // tslint:disable-next-line:no-console
        console.log('AUTH STATE - ON COMPLETE')
      })
  }

  getUserFromApi(): Observable<UserType> {
    return this.http.get<Array<UserType>>(this.config.userPath).pipe(
      catchError(r => of(null as Array<UserType>)),
      map(data => data instanceof Array ? data[0] || null : data)
    )
  }

  /**
   * Imposta un filtro che determina le condizioni per permettere all'utente di accedere.
   * In caso di esito negativo, il token salvato in precedenza viene eliminato
   */
  setPermission(newFilter: UserPermissionFilter<UserType>): void {
    this.permissionFilter = newFilter
  }

  /**
   * Rimuove eventuali filtri/permessi per l'accesso
   */
  clearPermission(): void {
    this.permissionFilter = null
  }

  /**
   * Effettua il login con email e password. Da on next se riesce ad ottenere un token.
   * Per avere lo stato completo dell'utente utilizzare userState
   */
  signup(name: string, surname = '', email: string, password: string, phone: string, accepted_marketing: boolean): Observable<AuthToken> {
    const body = {
      name,
      surname,
      email,
      password,
      phone,
      accepted_marketing
    }

    const req = this.http.post<UserType>(this.config.userPath, body)

    return req.pipe(
      concatMap(user => {
        console.warn('---O SUCCESS USER SIGNUP: ', user)

        return this.login(email, password)
      })
    )
  }

  /**
   * Effettua il login con email e password. Da on next se riesce ad ottenere un token.
   * Per avere lo stato completo dell'utente utilizzare userState
   */
  login(email: string, password: string): Observable<AuthToken> {
    const headers = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    // tslint:disable-next-line:prefer-template
    const body = 'client_id=' + this.config.clientID
      + '&client_secret=' + this.config.clientSecret
      + '&grant_type=' + 'password'
      + '&password=' + encodeURIComponent(password)
      + '&username=' + encodeURIComponent(email)
    const req = this.http.post<AuthToken>(this.config.authPath, body, headers)

    return req.pipe(
      concatMap(refreshedToken => {
        console.warn('---O SUCCESS TOKEN: ', refreshedToken.access_token)

        return this.saveAuthToken(refreshedToken)
      })
    )
  }
  /**
   * Cancella il token effettuando quindi il logout
   * @returns Observable che notifica il compimento dell'azione
   */
  logout(): Observable<AuthToken> {
    return this.removeAuthToken().pipe(
      tap(() => {
        if ((navigator as any).credentials && (navigator as any).credentials.preventSilentAccess) {
          (navigator as any).credentials.preventSilentAccess()
        }
      })
    )
  }

  getAuthToken(): Observable<AuthToken> {
    return fromPromise(this.storage.get('auth_token')).pipe(
      // Strateggia per anticipare refresh del token
      concatMap(authToken => {
        if (Date.now() < authToken.expireTime) {
          return of(authToken)
        } else {
          console.warn('Token EXPIRED')

          return this.syncronizeRefreshToken(authToken)
        }
      }),
      catchError(_ => of(null))
    )
  }

  syncronizeRefreshToken(expiredToken: AuthToken): Observable<AuthToken> {
    console.warn(`Call Refreshing TOKEN FOR: ${expiredToken.access_token}`)
    if (!this.isRefreshing) {
      this.isRefreshing = true
      console.warn(`> START Refreshing Token: ${expiredToken.access_token}`)
      if (expiredToken.refresh_token) {
        // tslint:disable-next-line:prefer-template
        const body = 'client_id=' + this.config.clientID
          + '&client_secret=' + this.config.clientSecret
          + '&grant_type=' + 'refresh_token'
          + '&refresh_token=' + expiredToken.refresh_token
        const headers = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
        const req = this.http.post<AuthToken>(this.config.authPath, body, headers)

        return req.pipe(
          concatMap(refreshedToken => {
            console.warn('@ SUCCESS Refreshed Token: ', refreshedToken.access_token)

            return this.saveAuthToken(refreshedToken)
          }),
          catchError(_ => {
            console.warn('X FAIL Refreshing Token')

            return this.removeAuthToken().pipe(
              map(() => {
                this.isRefreshing = false

                return {}
              })
            )
          })
        )
      } else {
        console.warn('X FAIL Can\'t Find Refresh Token for: ', expiredToken.access_token)

        return this.removeAuthToken().pipe(
          map(() => {
            this.isRefreshing = false

            return {}
          }))
      }
    } else {
      return this.refreshedToken.pipe(
        first()
      )
    }
  }

  private saveAuthToken(authToken: AuthToken): Observable<AuthToken> {
    authToken.expireTime = Date.now() + authToken.expires_in * 1000
    authToken.authorization = `${authToken.token_type} ${authToken.access_token}`

    return fromPromise(this.storage.set('auth_token', authToken)).pipe(
      map(() => {
        console.warn(`- SAVED NEW TOKEN --- ${authToken.authorization}`)
        this.isRefreshing = false
        this.refreshedToken.next(authToken)
        this.authStateSubject.next(true)

        return authToken
      })
    )
  }

  public pushNewUserState(userState: UserState<UserType>): void {
    this.lastUserState = userState || { user: null, logged: false, has_permission: false }
    this.lastUser = userState.user
    // tslint:disable-next-line:no-console
    console.log(`Push new User State: Logged ${this.lastUserState.logged} - Permission: ${this.lastUserState.has_permission}`)
    this.userStateSubject.next(this.lastUserState)
  }

  private removeAuthToken(): Observable<AuthToken> {
    return fromPromise(this.storage.remove('auth_token')).pipe(
      map(x => {
        console.warn('- DELETED TOKEN ---')
        this.authStateSubject.next(false)

        return {}
      })
    )
  }

}
