import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TimerComponent } from '../components/timer/timer.component';
import { SecondsAsClockPipe } from '../pipe/seconds-as-clock.pipe';
import { DeviceComponent } from '../components/device/device.component';
import { TimeItemComponent} from '../components/time-item/time-item.component'
import { SaveTimeComponent } from '../components/save-time/save-time.component';
import { DeviceCardComponent } from '../components/device-card/device-card.component';
import { TrackHeaderComponent } from '../components/track-header/track-header.component';

@NgModule({
  declarations: [
    TimerComponent,
    SecondsAsClockPipe,
    DeviceComponent,
    DeviceCardComponent,
    TimeItemComponent,
    TrackHeaderComponent,
    // SaveTimeComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    TimerComponent,
    SecondsAsClockPipe,
    DeviceComponent,
    DeviceCardComponent,
    TimeItemComponent,
    TrackHeaderComponent,
    // SaveTimeComponent
  ]
})
export class SharedModule { }
