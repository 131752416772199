import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-track-header',
  templateUrl: './track-header.component.html',
  styleUrls: ['./track-header.component.scss'],
})
export class TrackHeaderComponent implements OnInit {

  @Input() title: string
  @Input() small: boolean = false

  titleStyle = {}
  smallStyle = { 'font-size': "35px" }

  constructor() { }

  ngOnInit() {
    this.initStyle()
  }

  initStyle() {
    this.titleStyle = this.small ? this.smallStyle : {}
  }
}
