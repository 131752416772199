import { Component, OnInit, Input } from '@angular/core';
import { Proof } from 'src/app/models/models';

@Component({
  selector: 'app-time-item',
  templateUrl: './time-item.component.html',
  styleUrls: ['./time-item.component.scss'],
})
export class TimeItemComponent implements OnInit {

  @Input() time: Proof
  
  constructor() { }

  ngOnInit() {}

  get duration(){
    return (this.time.duration / 1000).toFixed(3)
  }
}
