import { Component, OnInit, Input, Output } from '@angular/core';
import { Device } from 'src/app/models/models';
import { ModalController } from '@ionic/angular';
import { DeviceService } from 'src/app/services/device.service';
import { DeviceLiveComponent } from '../device-live/device-live.component';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
})
export class DeviceComponent implements OnInit {

  @Input() device: Device
  @Input() selected: boolean

  @Input() hideTarget: boolean = false

  styleCircle = {}

  constructor(
    private readonly modalController: ModalController,
    private readonly deviceService: DeviceService
  ) {

  }

  ngOnInit() {
    this.initStyle()
  }

  initStyle() {
    this.styleCircle = {
      'background': this.device.color
    }
  }

  openDevicePreview() {
    this.presentPreview(this.device.id)
  }

  async presentPreview(deviceId: string) {
    this.deviceService.setLive(deviceId, true)
    const modal = await this.modalController.create({
      component: DeviceLiveComponent,
      componentProps: {
        'device': this.device
      },
      cssClass: "modal-fullscreen"
    })
    modal.onDidDismiss().then(() => {
      this.deviceService.setLive(deviceId, false)
      // this.modalController.dismiss(null, 'cancel')
    })

    return await modal.present()
  }

}
