import { Injectable } from "@angular/core"
import { Device, WifiSignal, DeviceSetup, DeviceStatus } from '../models/models';
import { AngularFireDatabase, DatabaseSnapshot, AngularFireAction } from '@angular/fire/database';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { take, concatMap, map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class DeviceService {
  private devices: Device[]
  private accountId: string

  constructor(private readonly db: AngularFireDatabase,
    private readonly sessionService: SessionService,
    private readonly auth: AuthService) {
    this.auth.getAccountId().subscribe(
      accountId => {
        this.accountId = accountId
      }
    )
  }
  
  setAccountId(id:string){
      if(id){
          this.accountId = id
      }
  }

  getDevices(): Observable<Device[]> {
    return this.db.list<Device>(this.devicesPath).valueChanges().pipe(
      map(devices => devices.filter(device => device.id))
    )
  }

  getDevicesConfig(): Observable<DeviceSetup> {
    return this.getDevices().pipe(
      map(devices => {
        let conf: DeviceSetup = {}
        devices.forEach(device =>
          conf[`${device.track}_${device.position}`] = device
        )
        conf.count = devices.length
        conf.one_track = devices.length < 4
        return conf
      })
    )
  }

  getDevice(id: string): Observable<Device> {
    return this.db.object<Device>(`${this.devicesPath}/${id}`).valueChanges()
  }

  private get devicesPath(): string {
    return `accounts/${this.accountId}/devices`
  }

  setDeviceConfig(id: string, track: string, position: string) {
    let payload = {
      track,
      position
    }
    return this.db.object(`${this.devicesPath}/${id}`).update(payload)
  }

  resetDeviceConfig(id: string) {
    return this.db.object(`${this.devicesPath}/${id}`).set(null).then(_ => {
      this.db.list<Device>(`${this.devicesPath}`)
    })
  }

  cleanDeviceConfig() {
    return this.db.list<Device>(this.devicesPath).valueChanges().pipe(
      take(1),
      concatMap(devices => {
        let payload = {}
        devices.forEach(device => {
          payload[`${device.id}/track`] = null
          payload[`${device.id}/position`] = null
        })
        return this.db.object(this.devicesPath).update(payload)
      })
    )
  }

  removeDevice(id: string) {
    return this.db.object(`${this.devicesPath}/${id}`).remove()
  }

  getFrames(path = 'frames'): Observable<AngularFireAction<DatabaseSnapshot<string>>[]> {
    return this.db.list<string>(`accounts/${this.accountId}/${path}`).snapshotChanges();
  }

  removeFrame(id: string, path = 'frames') {
    return this.db.object<string>(`accounts/${this.accountId}/${path}/${id}`).remove()
  }

  setLive(id: string, value: boolean) {
    return this.db.object(`${this.devicesPath}/${id}/is_live`).set(value)
  }

  getIsLive(id: string): Observable<boolean> {
    return this.db.object<boolean>(`${this.devicesPath}/${id}/is_live`).valueChanges()
  }

  getLive(id: string): Observable<string> {
    return this.db.object<string>(`accounts/${this.accountId}/devices_live/${id}`).valueChanges()
  }

  clearLive(id: string) {
    return this.db.object(`accounts/${this.accountId}/devices_live/${id}`).remove()
  }

  setTouchX(id: string, value: number) {
    return this.db.object(`${this.devicesPath}/${id}/touch_x`).set(value)
  }

  setTouchY(id: string, value: number) {
    return this.db.object(`${this.devicesPath}/${id}/touch_y`).set(value)
  }

  getWifiSignal(id: string): Observable<WifiSignal> {
    return this.db.object<WifiSignal>(`${this.devicesPath}/${id}/wifi`).valueChanges()
  }

  getDeviceStatus(id: string): Observable<DeviceStatus> {
    return this.db.object<DeviceStatus>(`${this.devicesPath}/${id}/status`).valueChanges()
  }

  getDevicePing(id: string): Observable<number> {
    return this.db.object<DeviceStatus>(`${this.devicesPath}/${id}/ping`).valueChanges()
  }

  sendPing(id: string, value: boolean) {
    return this.db.object(`${this.devicesPath}/${id}/send_ping`).set(value)
  }

}