import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserAuthModule } from './auth/user-auth.module';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { SharedModule } from './shared/shared.module';
import { Stripe } from '@ionic-native/stripe/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    UserAuthModule.forRoot({
      baseURL: 'https://test.mypushop.com/',
      authPath: 'https://test.mypushop.com/o/token/',      // path all'api del token
      userPath: 'https://test.mypushop.com/api/users/',    // path all'api dello user
      clientID: 'VCk4ohdf264lIg2BvS6v4sGCsP0NFEe9y3t32kBX',
      clientSecret: 'Pz8FZHlz76Xc7BXvc27tYCYBoXdbqgyvRgoVQeZQA15Z8g5D2YrpXC4YwBRUdWpEir3hvhLcC6FaVUggj280G7CXqHbXT8Y0eunNyeq1Ud8HHGFCgMdleV5rMA7Ne5Z1'
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FirebaseAuthentication,
    Stripe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
