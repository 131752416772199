import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'time', loadChildren: './pages/time/time.module#TimePageModule' },
  { path: 'remote', loadChildren: './pages/remote/remote.module#RemotePageModule' },
  { path: 'device-configure', loadChildren: './pages/device-configure/device-configure.module#DeviceConfigurePageModule' },
  { path: 'select-device-start', loadChildren: './pages/select-device-start/select-device-start.module#SelectDeviceStartPageModule' },
  { path: 'select-device-stop', loadChildren: './pages/select-device-stop/select-device-stop.module#SelectDeviceStopPageModule' },
  { path: 'session', loadChildren: './pages/session/session.module#SessionPageModule' },
  { path: 'new-session', loadChildren: './pages/new-session/new-session.module#NewSessionPageModule' },
  { path: 'frames', loadChildren: './pages/frames/frames.module#FramesPageModule' },
  { path: 'proofs/:id', loadChildren: './pages/proofs/proofs.module#ProofsPageModule' },
  { path: 'account', loadChildren: './pages/account/account.module#AccountPageModule' },
  { path: 'support', loadChildren: './pages/support/support.module#SupportPageModule' },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
