import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { SessionService } from './session.service';

@Injectable({
  providedIn: "root"
})
export class TimeService {

  public realStart: number
  public realStop: number
  public localStart: number
  public localStop: number

  public realStartDateTime: BehaviorSubject<number> = new BehaviorSubject(null); // Real Start Time
  public realEndDateTime: BehaviorSubject<number> = new BehaviorSubject(null); // Real End Time
  public localStartDateTime: BehaviorSubject<number> = new BehaviorSubject(null); // Local Start Time
  public localEndDateTime: BehaviorSubject<number> = new BehaviorSubject(null); // Local End Time

  constructor(private readonly db: AngularFireDatabase,
    private readonly sessionService: SessionService) {

  }

  public initRealStartTime() {
    this.realStartDateTime.next(null)
  }

  public initEndStartTime() {
    this.realEndDateTime.next(null)
  }

  public initLocalStartTime() {
    this.localStartDateTime.next(null)
  }

  public initLocalEndTime() {
    this.localEndDateTime.next(null)
  }

}