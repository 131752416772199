import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TimeService } from 'src/app/services/time.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { CronoStatus } from 'src/app/models/models';


@Component({
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimerComponent {

    @Input() visible: boolean

    private elapsedString: string = '--'
    private elapsed: number = null
    private timeStart: number = null
    private timeEnd: number = null
    firstDigit: string = '-'
    secondDigit: string = '-'
    mFirstDigit: string = '9'
    mSecondDigit: string = '9'
    // thirdDigit: string = '-'

    public status: CronoStatus

    private timer: any

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly timeService: TimeService
    ) {
        this.status = CronoStatus.READY
    }

    public startTimer(): void {

        this.processingTimer()
    }

    private processingTimer() {
        this.status = CronoStatus.START

        if (this.timer) {
            clearInterval(this.timer)
        }
        this.timeStart = Date.now()
        console.log("date now start: " + this.timeStart)
        this.elapsed = 0
        this.elapsedString = "00"
        this.setDigit()
        this.timer = setInterval(() => {
            this.timeEnd = Date.now()
            this.elapsed = parseInt("" + ((this.timeEnd - this.timeStart) / 1000))
            this.elapsedString = this.elapsed.toString().padStart(2, '0')
            this.setDigit()
        }, 1000)
    }

    public pauseTimer() {
        if (this.timer) {
            this.status = CronoStatus.PAUSE
            clearInterval(this.timer)
            if (this.elapsed === 0) {
                this.timeService.realStartDateTime.next(null)
            } else {
                this.timeService.localStartDateTime.next(this.timeStart)
                console.log("date now start: " + this.timeStart)
                this.timeService.localEndDateTime.next(this.timeEnd)
                console.log("date now end: " + this.timeEnd)
            }
            this.changeDetectorRef.markForCheck()
        }
    }

    public stopTimer() {
        if (this.timer) {
            this.status = CronoStatus.READY
            clearInterval(this.timer)
            this.elapsed = 0
            this.elapsedString = "--"
            this.timeService.localStartDateTime.next(null)
            this.timeService.localEndDateTime.next(null)
            this.timeService.realStartDateTime.next(null)
            this.timeService.realEndDateTime.next(null)
            this.setDigit()
        }
    }


    private setDigit() {
        if (this.elapsedString.length === 2) {
            this.firstDigit = this.elapsedString.substring(0, 1)

            this.secondDigit = this.elapsedString.substring(1, 2)

            // this.thirdDigit = this.elapsedString.substring(2)

            this.changeDetectorRef.markForCheck()
        }
    }


    public getTime() {
        return this.elapsed
    }

    public canStart() {
        return this.status == CronoStatus.PAUSE
    }

    public canStop() {
        return this.status == CronoStatus.START
    }
    // public correctTime() {
    //     if (this.timeService.realEndDateTime.value !== null && this.timeService.realStartDateTime.value != null
    //         && this.timeService.realEndDateTime.value >= this.timeService.realStartDateTime.value) {
    //         let time: string
    //         let timeDiff: number
    //         let timeSecond: number
    //         let timeMills: number
    //         timeDiff = this.timeService.realEndDateTime.value - this.timeService.realStartDateTime.value
    //         timeSecond = parseInt("" + (timeDiff / 1000))
    //         timeMills = timeDiff - (timeSecond * 1000)
    //         if (timeMills > 950) {
    //             timeSecond += 1
    //         }
    //         time = (timeSecond).toString().padStart(2, '0')
    //         console.log("time  " + time)
    //         if (time.length === 2) {
    //             this.elapsedString = time
    //             this.setDigit()
    //         } else {
    //             this.elapsedString = "99"
    //             this.setDigit()
    //         }
    //     } else {
    //         this.elapsedString = "00"
    //         this.setDigit()
    //     }
    // }




}
