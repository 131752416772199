import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'secondsAsClock'
})
export class SecondsAsClockPipe implements PipeTransform {

  transform(value: number, args?: any): string {
    if (!value) {
      return '000'
    }

    // const hours = Math.floor(value / 3600)
    // const minutes = Math.floor((value - (hours * 3600)) / 60)
    const seconds = value

    // let hoursString = ''
    // let minutesString = ''
    let secondsString = ''

    // hoursString = hours.toString().padStart(2, '0')
    // minutesString = minutes.toString().padStart(2, '0')
    secondsString = seconds.toString().padStart(3,'0')

    // return `${hoursString}:${minutesString}:${secondsString}`
    return `${secondsString}`
  }
}
